<template>
  <v-sheet  class="fill-height d-flex justify-center"
            :class="[
                { 'align-center' : !element.config.valign || element.config.valign === 'center'  },
                { 'align-baseline' :  element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
            color="transparent"
  >
    <v-divider :style="elementStyle"
               :vertical="element.config.vertical"  />
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementLineEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      element : { config : {}},
    }
  },
  computed : {
    elementStyle() {

      let style = ''
      if ( this.element.config.thickness ) {
        style +=  `border-width: ${this.element.config.thickness || 1}px;`
      }


      if ( this.element.config.background_color ) {
       style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      if ( this.blockStyle ) {
        style += `border-color : ${this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            'line_color'
        ) } ;`

      } else {
        style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      return style

    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>